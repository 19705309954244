$(function() {
    // Add class to body if main slider exists
    // if ($('#header .c-slider').length) {
    //     $('body').addClass('is-main-slider');
    // }

    // Event fields
    var eventTitle = $('#Inputfield_event_title');
    if (eventTitle.length) {
        eventTitle[0].value = $('#js-event-title').attr('data-content');
    }

    var eventFormType = $('#Inputfield_event_form_type');
    if (eventFormType.length) {
        eventFormType[0].value = $('#js-event-form-type').attr('data-content');
    }
});
