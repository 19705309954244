$(function() {
    $('[data-keep-ratio]').each(function() {
        var element = $(this);
        var width = parseInt(element.attr('data-width'));
        var height = parseInt(element.attr('data-height'));
        var ratio = width / height;

        element.keepRatio({
            ratio: ratio,
            calculate: 'height',
        });
    });
});
