$(function() {
    var mobileNav = $('.c-navigation.mobile-navigation');
    var desktopNav = $('.c-navigation.secondary-navigation');
    var mobileContent = mobileNav.find('.content-mobile');
    var generalContent = desktopNav.find('.content-general');
    var serviceContent = desktopNav.find('.content-service');
    var mobileTrigger = mobileNav.find('.trigger-mobile');
    var generalTrigger = desktopNav.find('.trigger-general');
    var serviceTrigger = desktopNav.find('.trigger-service');
    var mobileActiveClass = 'active-mobile';
    var generalActiveClass = 'active-general';
    var serviceActiveClass = 'active-service';

    function isActiveMobile() {
        return mobileNav.hasClass(mobileActiveClass);
    }

    function activateMobile() {
        mobileNav.addClass(mobileActiveClass);
    }

    function deactivateMobile() {
        mobileNav.removeClass(mobileActiveClass);
    }

    function isActiveGeneral() {
        return desktopNav.hasClass(generalActiveClass);
    }

    function activateGeneral() {
        desktopNav.addClass(generalActiveClass);
    }

    function deactivateGeneral() {
        desktopNav.removeClass(generalActiveClass);
    }

    function isActiveService() {
        return desktopNav.hasClass(serviceActiveClass);
    }

    function activateService() {
        desktopNav.addClass(serviceActiveClass);
    }

    function deactivateService() {
        desktopNav.removeClass(serviceActiveClass);
    }

    var ZellenkurHeight = function() {
        this.body = $('body');
        this.animationDuration = 1000;
        this.delayDuration = this.animationDuration;
        this.locked = false;
        this.lockClassName = 'js-zkr-height-locked';
        this.heightName = 'data-zkr-height';
        this.heightSelector = '[' + this.heightName + ']';
        this.changedHeightName = 'data-zkr-height-changed';
        this.changedHeightSelector = '[' + this.changedHeightName + ']';
    };
    ZellenkurHeight.prototype = {
        lock: function() {
            this.locked = true;
            this.body.addClass(this.lockClassName);

            return this;
        },
        unlock: function() {
            this.locked = false;
            this.body.removeClass(this.lockClassName);

            return this;
        },
        isLocked: function() {
            return this.locked;
        },
        update: function(elements) {
            var that = this;
            var updateHeight = _.delay(function(elements) {
                if (that.isLocked()) {
                    return false;
                }

                that.lock().updateHeight(elements).unlock();
            }, that.delayDuration, elements);

            return this;
        },
        updateHeight: function(elements) {
            var windowWidth = (window.innerWidth || document.documentElement.clientWidth);
            var windowHeight = (window.innerHeight || document.documentElement.clientHeight);

            elements = typeof elements !== 'undefined' ? elements : [];
            if (!(elements instanceof jQuery)) {
                elements = $(elements);
            }
            elements = elements.add(this.heightSelector);
            this.elements = elements;

            var that = this;
            elements.each(function() {
                var element = $(this);

                if (element.attr(that.heightName) === undefined) {
                    element.attr(that.heightName, '');
                }

                if (element.attr(that.changedHeightName) !== undefined) {
                    element.css('height', '');
                    element.css('overflow-y', '');
                    element.removeAttr(that.changedHeightName);
                }

                var rectangle = element[0].getBoundingClientRect();
                var isInViewport = (
                    rectangle.top >= 0 &&
                    rectangle.left >= 0 &&
                    rectangle.bottom <= windowHeight &&
                    rectangle.right <= windowWidth
                );
                var isShown = (
                    element.css('visibility') !== 'hidden' &&
                    element.css('display') !== 'none' &&
                    element.css('opacity') !== '0' &&
                    !element.is(':hidden')
                );

                if (isInViewport || !isShown) {
                    return true;
                }

                element.css('height', windowHeight - rectangle.top);
                element.css('overflow-y', 'auto');
                element.attr(that.changedHeightName, '');
            });

            if ($(this.changedHeightSelector).length) {
                this.body.css('overflow-y', 'hidden');
            } else {
                this.body.css('overflow-y', '');
            }

            return this;
        },
    };

    var zkrHeight = new ZellenkurHeight();
    zkrHeight.update();

    $(window).on('resize', _.debounce(function() {
        zkrHeight.update();
    }, 500));

    mobileTrigger.on('click', function() {
        if (isActiveMobile()) {
            deactivateMobile();
        } else {
            activateMobile();
        }

        var mobileContent = mobileNav.find('.content-mobile');
        zkrHeight.update(mobileContent);
    });

    generalTrigger.on('click', function() {
        if (isActiveGeneral()) {
            deactivateGeneral();
        } else {
            activateGeneral();
        }

        var generalContent = desktopNav.find('.content-general');
        zkrHeight.update(generalContent);
    });

    serviceTrigger.on('click', function() {
        if (isActiveService()) {
            deactivateService();
        } else {
            activateService();
        }

        var serviceContent = desktopNav.find('.content-service');
        zkrHeight.update(serviceContent);
    });

    // Use Headroom.js
    var headroomOptions = {
        tolerance: 10,
        onUnpin: function() {
            deactivateMobile();
            deactivateGeneral();
            deactivateService();
            zkrHeight.update();
        },
    };
    mobileNav.headroom(headroomOptions);
    desktopNav.headroom(headroomOptions);

    // Create focus area
    var keepFocusArray = [
        mobileNav.find('.navigation'),
        desktopNav.find('.navigation'),
        mobileContent,
        generalContent,
        serviceContent,
    ];
    var keepFocus = $([]);
    for (var i = 0; i < keepFocusArray.length; i++) {
        keepFocus = keepFocus.add(keepFocusArray[i]);
    }

    keepFocus.on('click', function(e) {
        e.stopPropagation();
    });

    // Hide on click outside of focus area
    $(window).on('click', function() {
        if (isActiveMobile() || isActiveGeneral() || isActiveService()) {
            deactivateMobile();
            deactivateGeneral();
            deactivateService();
            zkrHeight.update();
        }
    });
});
