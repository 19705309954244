$(function() {
    function objectFitImages(images) {
        var bots = /bot|googlebot|crawler|spider|robot|crawling/i;

        if (bots.test(navigator.userAgent) ||
            typeof images === 'undefined') {
            return false;
        }

        if (!(images instanceof jQuery)) {
            images = $(images);
        }

        images.each(function() {
            var image = $(this);

            image
            .wrap('<div class="object-fit-wrapper"></div>')
            .after('<div class="object-fit"></div>');

            // var objectFitWrapper = image.parent();
            var objectFit = image.next();

            objectFit
            .addClass(image.attr('class'))
            .css('background-image', 'url("' + encodeURI(image.attr('src')) + '")')
            .attr('title', image.attr('alt'));

            image.remove();
        });
    }

    // Use objectFitImages for .c-slider
    objectFitImages($('.c-slider:not(.is-proportional) img'));

    var $temp = $('<div style="background:none;display:none;"></div>').appendTo('body');
    var transparent = $temp.css('background-color');
    $temp.remove();

    function getHeightClass(classes) {
        var regex = /(column-height-[^\s]+)/;
        var matches = regex.exec(classes);

        if (matches === null) {
            return null;
        }

        return matches[1];
    }

    function isOverflow() {
        $('.c-text .text-content').each(function() {
            var text = $(this);
            var column = text.closest('.column');

            // Add height
            if (typeof column.data('heightClass') !== 'undefined') {
                column.addClass(column.data('heightClass'));
            }

            // Get height
            var heightClass = getHeightClass(column.attr('class'));

            if (heightClass === null) {
                return;
            }

            if (typeof column.data('heightClass') === 'undefined') {
                column.data('heightClass', heightClass);
            }

            // Remove height on small
            if (!Foundation.MediaQuery.atLeast('medium')) {
                column.removeClass(heightClass);
                return;
            }

            if (!(text[0].scrollHeight > text[0].clientHeight)) {
                return;
            }

            column.removeClass(heightClass);

            // Auto-change background of row
            // var customBackground = column.find('.custom-background');

            // if (!customBackground.length) {
            //     return;
            // }

            // var row = column.closest('.row');

            // if (row.css('background-color') !== transparent) {
            //     return;
            // }

            // row.css('background-color', customBackground.css('background-color'));
        });
    }

    (function loopIsOverflow() {
        isOverflow();
        setTimeout(loopIsOverflow, 2000);
    })();

    $('.c-slider.is-proportional').each(function() {
        var column = $(this).closest('.column');
        var heightClass = getHeightClass(column.attr('class'));
        column.removeClass(heightClass);
    });

    // Disable behavior of fake links
    $('a[href="#"]').on('click', function(e) {
        e.preventDefault();
    });

    // Smooth scroll
    $('a').smoothScroll();

    // Iframe resize
    $('.c-map').iFrameResize({
        scrolling: true,
    });

    // Masonry & imagesLoaded
    var $masonry = $('.c-masonry').masonry({
        itemSelector: '.c-masonry-item',
    });
    $masonry.imagesLoaded().progress(function() {
        $masonry.masonry('layout');
    });

    // Description
    $('.c-masonry img[alt]').each(function() {
        var image = $(this);
        var alt = image.attr('alt');
        image.after('<div class="img-description">' + alt + '</div>');
    });

    // Scroll animations
    // ScrollMagic

    var ZellenkurAnimate = function(options) {
        options = typeof options !== 'undefined' ? options : {};
        this.options = options;
        this.controller = this.initController(this.options);

        $('html').addClass('scrollmagic');
    };
    ZellenkurAnimate.prototype = {
        initController: function(options) {
            options = typeof options !== 'undefined' ? options : {};
            var controller = new $.ScrollMagic.Controller(options);

            return controller;
        },
        animate: function(selector, animation, delay, delayDuration, animationDuration) {
            delay = typeof delay !== 'undefined' ? delay : 0;
            delayDuration = typeof delayDuration !== 'undefined' ? delayDuration : 0;
            animationDuration = typeof animationDuration !== 'undefined' ? animationDuration : 1000;

            if (!(selector instanceof jQuery)) {
                selector = $(selector);
            }

            var animationClasses = 'animated ' + animation;
            var visibleClass = 'animated-visible';
            var that = this;

            selector.each(function(i) {
                var element = $(this);
                var elementDelay = delay + (i * delayDuration);

                var scene = new $.ScrollMagic.Scene({
                    triggerElement: element,
                    reverse: false,
                })
                .on('start', function(event) {
                    _.delay(function() {
                        element.addClass(animationClasses);
                        element.addClass(visibleClass);

                        _.delay(function() {
                            element.removeClass(animationClasses);
                        }, animationDuration);
                    }, elementDelay);
                })
                .addTo(that.controller);
            });

            return this;
        },
    };

    var zkrAnimate = new ZellenkurAnimate({
        refreshInterval: 500,
        globalSceneOptions: {
            triggerHook: .95,
        },
    });

    zkrAnimate
    .animate([
        '.primary-navigation .logo',
        '.secondary-navigation .navigation-general',
        '.secondary-navigation .navigation-service',
    ], 'fadeInDown', 0, 250)
    .animate('.primary-navigation .vertical.menu > li', 'fadeIn', 750, 250)
    .animate('.primary-navigation .vertical.menu > li > a', 'flipInX', 750, 250)
    .animate([
        '.primary-navigation .small-content',
        '.primary-navigation .slogan',
    ], 'fadeIn', 2000, 250)
    .animate('#content .c-gallery', 'fadeIn', 250, 125)
    .animate('#content .c-text', 'fadeIn', 250, 125)
    .animate('.c-zellenkur', 'rotateIn', 1000)
    .animate('#footer', 'fadeIn', 250);

    // ScrollReveal
    var scrollreveal = ScrollReveal({
        duration: 500,
        viewFactor: 0.33,
    });

    if (scrollreveal.isSupported()) {
        $('html').addClass('scrollreveal');
    }

    // var selector = '#header .dropdown-menu-wrapper > .dropdown.menu > li > a';
    // scrollreveal.reveal(selector, {}, 100);

    // $('.events .event').wrap('<div class="sc-wrapper"></div>');
    // var selector = '.events .sc-wrapper';
    // scrollreveal.reveal(selector, {}, 100);

    // $('.sidebar > *').wrap('<div class="sc-wrapper"></div>');
    // var selector = '.sidebar .sc-wrapper';
    // scrollreveal.reveal(selector, {}, 100);

    // $('#footer > .content > *').wrap('<div class="sc-wrapper"></div>');
    // var selector = '#footer .sc-wrapper';
    // scrollreveal.reveal(selector, {
    //     duration: 1000,
    // }, 100);

    // Fake height
    function fakeHeight() {
        var elementName = 'fake-height';
        var elementSelector = '[data-' + elementName + ']';
        var elementClassName = 'fake-height-class';
        var elementClassSelector = '[data-' + elementClassName + ']';
        var wrapperName = 'fake-height-wrapper';
        var wrapperSelector = '.' + wrapperName;
        var sourceName = 'fake-height-source';
        var sourceSelector = '[data-' + sourceName + ']';
        var elements = $(elementSelector);

        elements.each(function() {
            var element = $(this);

            if (!element.parent(wrapperSelector).length) {
                var wrapperClass = wrapperName;
                var elementClass = element.attr('data-' + elementClassName);

                if (elementClass !== undefined && elementClass !== '') {
                    wrapperClass += ' ' + elementClass;
                }

                element.wrap('<div class="' + wrapperClass + '"></div>');
            }

            var wrapper = element.parent(wrapperSelector);

            if (!wrapper.length) {
                return true;
            }

            var source = element.find(sourceSelector);

            if (!source.length) {
                source = element;
            }

            var sourceHeight = 0;
            source.each(function() {
                sourceHeight += $(this).outerHeight();
            });

            wrapper.outerHeight(sourceHeight);
        });
    }

    // Running to infinity!
    (function loopFakeHeight() {
        fakeHeight();
        setTimeout(loopFakeHeight, 1000);
    })();
});

$(document).foundation();
