$(function() {
    var eventsWrapper = $('.c-events');
    var events = eventsWrapper.find('.event');
    var event;
    var filter = eventsWrapper.find('.events-filter');
    var $masonry = eventsWrapper.find('.c-masonry');
    var activeClass = 'active';
    var darkClass = 'vibrant-dark';
    var doneClass = 'vibrant-done';
    var image;
    var swatches;
    var swatch = 'DarkVibrant';
    var color;
    var bodyColor;
    var vibrant;

    // Filtering
    if (filter.length) {
        $masonry.isotope({
            hiddenStyle: {
                opacity: 0,
                transform: 'scale(0.75) rotate(-5deg)',
            },
            visibleStyle: {
                opacity: 1,
                transform: 'scale(1) rotate(0deg)',
            },
        });
    }

    filter.find('.option a[data-filter]').on('click', function(e) {
        e.preventDefault();

        var anchor = $(this);
        var option = anchor.parent('.option');
        var filterValue = anchor.attr('data-filter');

        if (option.hasClass(activeClass)) {
            filterValue = '*';
            option.removeClass(activeClass);
        } else {
            filter.find('.' + activeClass).removeClass(activeClass);
            option.addClass(activeClass);
        }

        $masonry.isotope({
            filter: filterValue,
        });
    });

    // Background colors
    eventsWrapper.imagesLoaded(function() {
        events.each(function() {
            event = $(this);
            image = event.find('.image').first();

            if (event.hasClass(doneClass) ||
                !event.is(':visible') ||
                image[0] === undefined) {
                return true;
            }

            try {
                vibrant = new Vibrant(image[0]);
            } catch (e) {
                vibrant = null;
            }

            if (!vibrant) {
                return true;
            }

            swatches = vibrant.swatches();
            color = null;
            bodyColor = null;

            if (swatches.hasOwnProperty(swatch) && swatches[swatch]) {
                color = swatches[swatch].getHex();
                bodyColor = swatches[swatch].getBodyTextColor();
            }

            if (color) {
                event.css('background-color', color);
            }

            if (bodyColor === '#fff') {
                event.addClass(darkClass);
            }

            if (color || bodyColor === '#fff') {
                event.addClass(doneClass);
            }
        });
    });
});
